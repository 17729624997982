var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "side" },
    [
      _c(
        "router-link",
        {
          staticClass: "side-content button",
          class: { active: _vm.isActivePath("/event") },
          attrs: { to: { name: "eventTheme" } },
        },
        [_vm._v(" 募集イベント ")]
      ),
      _c(
        "router-link",
        {
          staticClass: "side-content button",
          class: { active: _vm.isActivePath("/entries") },
          attrs: { to: { name: "entries" } },
        },
        [_vm._v(" 応募履歴 ")]
      ),
      _c(
        "router-link",
        {
          staticClass: "side-content button",
          class: { active: _vm.isActivePath("/entryProfile") },
          attrs: { to: { name: "entryProfile" } },
        },
        [_vm._v(" 応募プロフィール ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }